.mountain-container {
  position: relative;
  height: 180px;
  width: 100vw;
  padding: 0;
  margin: 0;
  top: 60px;
  left: -50px;
}

.bg{
  overflow:hidden;
  position: absolute;
  top: 0;
  left:0;
  right:0;
  bottom:0;
}
.mountain, .mountain-two, .mountain-three {
  position: absolute;
  bottom: 0;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-bottom: 180px solid var(--yellow-dark);
  z-index: 1;
}

.mountain-two { 
  left: 80px;
  bottom: -50px;
  opacity: .3;
  z-index: 0;
}
.mountain-three {
  left: -60px;
  bottom:-10px;
  opacity: .5;
  z-index: 0;
}
.mountain-top {
  position: absolute;
  right: -65px;
  border-left: 65px solid transparent;
  border-right: 65px solid transparent;
  border-bottom: 77px solid var(--yellow-med);
  z-index: 2;
}


.mountain-cap-1, .mountain-cap-2, .mountain-cap-3 {
  position: absolute;
  top: 70px;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid var(--yellow-med);
}
.mountain-cap-1 { left: -55px; }
.mountain-cap-2 { left: -25px; }
.mountain-cap-3 { left: 5px; }
.cloud, .cloud:before, .cloud:after {
position: absolute;
width: 70px;
height: 30px;
background: var(--yellow-light);
-webkit-border-radius: 100px / 50px;
border-radius: 100px / 50px;
}
.cloud { 
bottom: 85px;
-webkit-animation: cloud 50s infinite linear;
        animation: cloud 50s infinite linear;
}
@-webkit-keyframes cloud {
  0%   { left: -100px; }
  100% { left: 2000px; } 
}
@keyframes cloud {
 
  0%   { left: -100px; }
  100% { left: 1000px; } 
}
.cloud:before {
content: '';
left: 50px;
}
.cloud:after {
content: '';
left: 25px;
top: -10px;
}

.cloud-two, .cloud-two:before, .cloud-two:after {
  position: absolute;
  width: 70px;
  height: 30px;
  background: var(--yellow-light);
  -webkit-border-radius: 100px / 50px;
  border-radius: 100px / 50px;
  }
  .cloud-two { 
  bottom: 135px;
  -webkit-animation: cloud 35s infinite linear;
          animation: cloud 35s infinite linear;
  }
  @-webkit-keyframes cloud-two {
    0%   { left: -100px; }
    100% { left: 2000px; } 
  }
  @keyframes cloud-two {
   
    0%   { left: -100px; }
    100% { left: 2000px; } 
  }
  .cloud-two:before {
  content: '';
  left: 50px;
  }
  .cloud-two:after {
  content: '';
  left: 25px;
  top: -10px;
  }
