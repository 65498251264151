.freeImg-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--deep-rose);
  align-items: center;
  justify-content: center;
  padding: 2.5rem 0;
}

.img-download {
  width: 80%;
  text-align: center;
  border: 4px solid var(--deep-rose);
  background-color: var(--white);
  padding: 1.5rem;
  transition: all 0.3s ease-out;
}

.img-download:hover {
  transform: translateY(-5px) scale(1) translateZ(0);
}

.img-cover {
  box-sizing: border-box;
}

.download-btn a:link {
  display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	padding: .9rem 1rem;
	text-decoration: none;
	font-size: 1.5rem;
	letter-spacing: 1px;
	font-weight: 600;
  border: 2px solid var(--deep-rose);
  color: var(--deep-rose);
}

.download-btn a:visited {
	border: 2px solid var(--deep-rose);
	color: var(--deep-rose);
}

.download-btn a:hover{
	border: 2px solid var(--deep-rose);
	background-color: var(--deep-rose);
	color: var(--white);
}