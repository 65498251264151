@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 1rem;
  background: var(--white);
  z-index: 1;
}
.nav-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.nav-header h1 {
  margin: 0;
  font-size: 2rem;
}

.logo-link {
  color: var(--primary);
  text-decoration: none;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
}

.nav-icon {
  position: relative;
  top: 8px;
  left: 6px;
  font-size: 1.5rem;
  color: var(--primary);
}

.nav-links {
  display: -webkit-flex;
  display: flex;
  height: 0;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
}

.nav-links a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-decoration: none;
  color: var(--primary);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}

.links-container ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
  padding: 0;
}

.nav-links li {
  padding-left: 1rem;
  padding-top: .5rem;
}

.nav-links a:hover {
  color: var(--medBlue);
}

.show-nav {
  height: 25px;
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }

  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .links-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 760px;
    margin-left: 2rem;
  }

  .nav-links {
    height: auto;
    display: -webkit-flex;
    display: flex;
    margin-left: 4rem;
  }

  .nav-links a {
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0.5rem 0;
  }

  .nav-path-link {
    text-align: center;
    margin: 0;
  }
}

.activity-card {
  background-color: var(--lightBlue);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 375px;
  width: 340px;
  transition: all 0.3s ease-out;

}

.activity-card:hover {
  -webkit-transform: translateY(-5px) scale(1) translateZ(0);
          transform: translateY(-5px) scale(1) translateZ(0);
}

.title-card {
  display: -webkit-flex;
  display: flex;
  width: 320px;
  overflow: hidden;
  padding: 0 1.5rem;
  text-transform: capitalize;
}

.title-card a:link {
  color: var(--primary);
  text-decoration: none;
}

.preview-text {
  padding: 0 1.5rem;
  font-size:1.5rem;
  width:320px;
  
  /** Properties **/
  overflow:hidden;
  line-height: 2rem;
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.activity-card h2 {
  color: var(--primary);
  margin: 0;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  line-height: 2.25rem;
}

.activity-card p {
  font-size: 1.5rem;
  margin: 0;
  color: var(--primary);
}

.activity-card textarea::-webkit-input-placeholder {
  color: rgba(250, 250, 250, .6);
}

.activity-card textarea:-ms-input-placeholder {
  color: rgba(250, 250, 250, .6);
}

.activity-card textarea::placeholder {
  color: rgba(250, 250, 250, .6);
}

.activity-card .hr {
  width: 90%;
  margin-bottom: 1rem;
  height: 3px;
  background-color: var(--primary);
}

.activity-card .lower-hr {
  height: 1.5px;
}
.click-card-container {
  display: -webkit-flex;
  display: flex;
}

.activity-card-btn {
  display: inline-block;
	margin-top: 1.5rem;
	margin-right: 1rem;
	padding: .9rem 1rem;
	text-decoration: none;
	font-size: 1rem;
	letter-spacing: 1px;
	font-weight: 600;
  width: 105px;
}

.input-btn {
  background: none;
  border: none;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.activity-card-btn:link,
.activity-card-btn:visited {
	border: 2px solid var(--primary);
	color: var(--primary);
}

.activity-card-btn:hover{
	border: 2px solid var(--medBlue);
	background-color: var(--medBlue);
	color: var(--white);
}
.activity-list-container {
  background-color: var(--deep-rose);
  padding: 2.5rem 2rem;
}

.act-list-top-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 1.5rem;
}

.add-btn {
  display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	padding: .9rem 1rem;
	text-decoration: none;
	font-size: 1.5rem;
	letter-spacing: 1px;
	font-weight: 600;
  border: 2px solid var(--white);
	color: var(--white);
}

.activities-list {
  margin: 0;
  padding: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.activity-item {
  
  margin-bottom: 1rem;
  padding: .5rem;
}

.activity-list-container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width:769px) {
  .activities-list {
    margin: 0 auto;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 2.rem;
  }
}
.single-activity {
  background-color: var(--lightBlue);
  color: var(--primary);
  width: 100%;
  padding-bottom: 2rem;
}

.single-activity-info {
  width: 80vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  padding: 2rem 0;
}

.activity-title {
  width: 100%;
  padding-left: 1rem;
}


.directions, .supplies {
  white-space: pre-line;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.activity-title h2 {
  margin-bottom: .25rem;
}

.activity-title h2, .directions h3, .supplies h3 {
  color: var(--primary);
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.activity-title h3 {
  margin-top: 0;
}

.supplies {
  width: 80vw;
  margin: 0 auto 3rem auto;
}

.hr {
  width: 100%;
  height: 3px;
  background-color: var(--primary);
}

.lower {
  height: 1.5px;
  margin-bottom: 1rem;
}

.single-activity .back-btn {
  margin-right: 1rem;
  display: inline-block;
	padding: .9rem 1rem;
	text-decoration: none;
	font-size: 1.5rem;
	letter-spacing: 1px;
	font-weight: 600;
  border: 2px solid var(--primary);
  background: none;
  color: var(--primary);
}

.single-activity .back-btn:hover {
  border: 2px solid var(--medBlue);
  background: white;
  color: var(--medBlue);
}

.back-container {
  width: 100%;
  padding: 1rem;
}

@media (min-width:769px) {
	.single-activity-info {
		-webkit-flex-direction: row;
		        flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
	}
}
.freeImg-container {
  width: 100%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: var(--deep-rose);
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2.5rem 0;
}

.img-download {
  width: 80%;
  text-align: center;
  border: 4px solid var(--deep-rose);
  background-color: var(--white);
  padding: 1.5rem;
  transition: all 0.3s ease-out;
}

.img-download:hover {
  -webkit-transform: translateY(-5px) scale(1) translateZ(0);
          transform: translateY(-5px) scale(1) translateZ(0);
}

.img-cover {
  box-sizing: border-box;
}

.download-btn a:link {
  display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	padding: .9rem 1rem;
	text-decoration: none;
	font-size: 1.5rem;
	letter-spacing: 1px;
	font-weight: 600;
  border: 2px solid var(--deep-rose);
  color: var(--deep-rose);
}

.download-btn a:visited {
	border: 2px solid var(--deep-rose);
	color: var(--deep-rose);
}

.download-btn a:hover{
	border: 2px solid var(--deep-rose);
	background-color: var(--deep-rose);
	color: var(--white);
}
.add-activity-container {
  background-color: var(--deep-rose);
  width: 100vw;
}

.activity-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 4rem 0;
  color: var(--white);
}

.activity-form hr {
  margin: 0;
}

.add-activity-container h2 {
  padding: 0;
  margin: 0;
  line-height: 3.5rem;
  font-size: 2.5rem;
  color: var(--white);
}

.max-ct {
  font-size: 1.5rem;
}

.field-supplies, 
.field-description {
  margin-top: 3rem;
}

#activity-name-input, 
#supply-input, 
#aboutActivity-input {
  border: none;
  border-bottom: 2px solid var(--white);
  width: 80vw;
  background: none;
  color: var(--white);
  height: 50px;
}

#supply-input {
  height: 100px;
}

#aboutActivity-input {
  height: 175px;
}

.add-activity-container textarea {
  resize: none;
  font-size: 1.5rem;
  overflow-y:inherit;
}

.add-activity-container textarea::-webkit-input-placeholder {
  font-size: 1.5rem;
  color: var(--white);
}

.add-activity-container textarea:-ms-input-placeholder {
  font-size: 1.5rem;
  color: var(--white);
}

.add-activity-container textarea::placeholder {
  font-size: 1.5rem;
  color: var(--white);
}

.add-activity-container button {
  display: inline-block;
	margin-top: 3.5rem;
	margin-right: 1rem;
	padding: .9rem 1rem;
	text-decoration: none;
	font-size: 1.5rem;
	letter-spacing: 1px;
	font-weight: 600;
  border: 2px solid var(--white);
  background: none;
  color: var(--white);
}

.add-activity-container button:hover {
  border: 2px solid white;
  background: white;
  color: var(--deep-rose);
}

@media (min-width:769px) {
  #activity-name-input, 
  #supply-input, 
  #aboutActivity-input {
    max-width: 800px; 
  }
}
.contact-container {
  background-color: var(--deep-rose);
  width: 100vw;
}

.contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 4rem 0;
  color: var(--white);
}

.contact-form hr {
  margin: 0;
}

.contact-form h2 {
  padding: 0;
  margin: 0;
  line-height: 3.5rem;
  font-size: 2.5rem;
  color: white;
}

.field-user-email, 
.field-user-message {
  margin-top: 3rem;
}

#user-name, 
#user-email, 
#user-message {
  border: none;
  border-bottom: 2px solid var(--white);
  width: 80vw;
  background: none;
  color: var(--white);
  height: 50px;
}

#user-name {
  height: 50px;
}

#user-email {
  height: 50px;
}

#user-message {
  height: 175px;
}
.contact-container textarea {
  resize: none;
  font-size: 1.5rem;
  resize: none;
  
}

.contact-container textarea::-webkit-input-placeholder {
  color: rgba(250, 250, 250, 0.6);
}

.contact-container textarea:-ms-input-placeholder {
  color: rgba(250, 250, 250, 0.6);
}

.contact-container textarea::placeholder {
  color: rgba(250, 250, 250, 0.6);
}

.contact-container input {
  resize: none;
  font-size: 1.5rem;
  resize: none;
}

.contact-container input::-webkit-input-placeholder {
  color: rgba(250, 250, 250, 0.6);
}

.contact-container input:-ms-input-placeholder {
  color: rgba(250, 250, 250, 0.6);
}

.contact-container input::placeholder {
  color: rgba(250, 250, 250, 0.6);
}

.contact-container button {
  display: inline-block;
	margin-top: 3.5rem;
	margin-right: 1rem;
	padding: .9rem 1rem;
	text-decoration: none;
	font-size: 1.5rem;
	letter-spacing: 1px;
	font-weight: 600;
  border: 2px solid var(--white);
  background: none;
  color: var(--white);
}

.contact-container button:hover {
  border: 2px solid white;
  background: white;
  color: var(--deep-rose);
}

@media (min-width:769px) {
  #user-name, 
  #user-email, 
  #user-message {
    max-width: 800px; 
  }
}
/* ---------------------------------- */
   
/*  FOOTER  */

/* ---------------------------------- */

.footer-content {
	background-color: var(--yellow);
  padding: 2rem 2rem 3.5rem 2.5rem;
  overflow: hidden;
}

.footer-content h3 {
  margin: 0;
  padding-top:  3rem;
  color: var(--deep-yellow);
}

.footer-content p {
  margin: 0;
}

.footer-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.contact-container p {
  margin: 0;
}

.footer-content p, .contact-container p {
  color: var(--deep-yellow);
}
/*  FOOTER - STAY UPDATED  */


.email-link {
	text-decoration: none;
	color: var(--deep-yellow);
}

.email-link:link,
.email-link:visited {
	color: var(--deep-yellow);
}

.email-link:hover{
	color: #fff;
}

.footer-mood {
  grid-column: 2 / 3;
}

.sign-up {
	grid-column: 3 / 4;
	line-height: 1.5;
	letter-spacing: .1rem;
  padding-right: 3rem;
}

#email::-webkit-input-placeholder {
  color: var(--deep-yellow);
}

#email:-ms-input-placeholder {
  color: var(--deep-yellow);
}

#email::placeholder {
  color: var(--deep-yellow);
}

@media only screen and (min-width:768px){
	.footer-grid {
		display: grid;
		grid-template-columns: 1.5rem 1fr 1fr 1.5rem;
	}

  .sign-up {
    padding-left: 3rem;
  }

  .email-sign-up {
		grid-column: 3 / 4;
    margin-top: 1rem;
	}
	
	.footer-mood {
		grid-column: 2 / 3;
    grid-row: 1 / 2;
	}

  .mountain-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0;
  }
}

.footer-content input[type=text], input[type=email] {
  border: none;
  border-bottom: 2px solid var(--deep-yellow); 
  background: none;
  font-family:  var(--pFont);
  color: var(--deep-yellow);
  width: 250px;
}

.footer-content input[type=text], .footer-email {
  width: 200px;
}

@media only screen and (min-width:768px) {
  .footer-content input[type=text], input[type=email], textarea {
  width: 315px;
  }

  .footer-content input[type=text], .footer-email {
  width: 200px;
  margin-top: 1rem;
}
}
.footer-content input[type=submit] {
  background: var(--yellow);
  margin-left: 2rem;
  margin-top: 2rem;
  border: 2px solid var(--deep-yellow);
  padding: .5rem .75rem;
  font-family:  var(--pFont);
  font-weight: 600;
  color: var(--deep-yellow);
  cursor: pointer;
}

.footer-content input[type=submit]:hover {
  border: 2px solid var(--deep-yellow);
	background-color: var(--deep-yellow);
	color: var(--white);
}

.email-label {
  font-weight: 600;
  color: var(--deep-yellow);
  margin-bottom: 1rem;
}


.mountain-container {
  position: relative;
  height: 180px;
  width: 100vw;
  padding: 0;
  margin: 0;
  top: 60px;
  left: -50px;
}

.bg{
  overflow:hidden;
  position: absolute;
  top: 0;
  left:0;
  right:0;
  bottom:0;
}
.mountain, .mountain-two, .mountain-three {
  position: absolute;
  bottom: 0;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-bottom: 180px solid var(--yellow-dark);
  z-index: 1;
}

.mountain-two { 
  left: 80px;
  bottom: -50px;
  opacity: .3;
  z-index: 0;
}
.mountain-three {
  left: -60px;
  bottom:-10px;
  opacity: .5;
  z-index: 0;
}
.mountain-top {
  position: absolute;
  right: -65px;
  border-left: 65px solid transparent;
  border-right: 65px solid transparent;
  border-bottom: 77px solid var(--yellow-med);
  z-index: 2;
}


.mountain-cap-1, .mountain-cap-2, .mountain-cap-3 {
  position: absolute;
  top: 70px;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid var(--yellow-med);
}
.mountain-cap-1 { left: -55px; }
.mountain-cap-2 { left: -25px; }
.mountain-cap-3 { left: 5px; }
.cloud, .cloud:before, .cloud:after {
position: absolute;
width: 70px;
height: 30px;
background: var(--yellow-light);
border-radius: 100px / 50px;
}
.cloud { 
bottom: 85px;
-webkit-animation: cloud 50s infinite linear;
        animation: cloud 50s infinite linear;
}
@-webkit-keyframes cloud {
  0%   { left: -100px; }
  100% { left: 2000px; } 
}
@keyframes cloud {
 
  0%   { left: -100px; }
  100% { left: 1000px; } 
}
.cloud:before {
content: '';
left: 50px;
}
.cloud:after {
content: '';
left: 25px;
top: -10px;
}

.cloud-two, .cloud-two:before, .cloud-two:after {
  position: absolute;
  width: 70px;
  height: 30px;
  background: var(--yellow-light);
  border-radius: 100px / 50px;
  }
  .cloud-two { 
  bottom: 135px;
  -webkit-animation: cloud 35s infinite linear;
          animation: cloud 35s infinite linear;
  }
  @-webkit-keyframes cloud-two {
    0%   { left: -100px; }
    100% { left: 2000px; } 
  }
  @keyframes cloud-two {
   
    0%   { left: -100px; }
    100% { left: 2000px; } 
  }
  .cloud-two:before {
  content: '';
  left: 50px;
  }
  .cloud-two:after {
  content: '';
  left: 25px;
  top: -10px;
  }

:root {
	--primary: #3b5ca9;
	--medBlue: #4e9bcb;
	--lightBlue: #c2e4f9;
	--rose: #ff5f43;
	/* --rose: #faa090; */
	--deep-rose: #de6d5a;
	--deep-yellow: #614e13;
	--yellow: #f9e6ae;
	--yellow-dark: #eed074;
	--yellow-med: #e8c868;
	--yellow-light: #fef2c9;
	--white: #fff;
	--gray: #eeeeee;
	--black: #222222;
	--TitleFont: 'Rokkitt', serif;
	--pFont: 'Open Sans', sans-serif;
	--mainTransition: all 0.3s linear;
}

html{
	box-sizing: border-box;
	height: 100%;
	margin: 0;
}
*, *:before, *:after{
	box-sizing: inherit;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-family: var(--pFont);
	min-height: 100%;
	text-rendering: optimizeLegibility;
	margin: 0;
	padding: 0;
	padding-top: 87px;
}

/* ---------------------------------- */
   
/*  GLOBAL STYLES */

/* ---------------------------------- */
.container {
	max-width: 1200px;
	width: 100%;
  margin: 0 auto;
}

/*  TYPOGRAPHY */
h2 {
	font-family: 'Rokkitt', serif;
	font-family: var(--TitleFont);
	font-size: 2.75rem;
	line-height: 3rem;
	letter-spacing: 1px;
	color: black;
}

h3 {
	color: black;
}

p {
	color: black;
	font-family: 'Open Sans', sans-serif;
	font-family: var(--pFont);
	font-weight: 600;
	font-size: 1rem;
	letter-spacing: 1px;
	line-height: 1.85rem;
}

.dark-text {
	color: black;
}

/*  IMAGES */
img {
	width: 100%;
	display: block; 
}

/*  BUTTONS */
.btns {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	padding: .9rem 1rem;
	text-decoration: none;
	font-size: 1rem;
	letter-spacing: 1px;
	font-weight: 600;
}

.btn-full:link,
.btn-full:visited {
	border: 2px solid #fff;
	border: 2px solid var(--white);
	color: #fff;
	color: var(--white);
}

.btn-full:hover,
.btn-primary:hover {
	border: 2px solid #fff;
	border: 2px solid var(--white);
	background-color: #fff;
	background-color: var(--white);
	color: #3b5ca9;
	color: var(--primary);
}

.btn-primary {
	border: 2px solid #3b5ca9;
	border: 2px solid var(--primary);
	background-color: none;
	color: #3b5ca9;
	color: var(--primary);
}


/* ---------------------------------- */
   
/*  HOME */

/* ---------------------------------- */


.about-container, .activities-container, .prints-container {
	display: grid;
	grid-template-columns: 1.5rem minmax(250px, 1fr) 1.5rem;
	grid-template-rows: 1rem 1fr 2rem auto;
	background-color: #3b5ca9;
	background-color: var(--primary);
}

.activities-container {
	background-color: #c2e4f9;
	background-color: var(--lightBlue);
}

.about-container h2, .prints-container h2 {
	color: #c2e4f9;
	color: var(--lightBlue);
}

.activities-container h2, p {
	color: #3b5ca9;
	color: var(--primary);
}

.about-container p, .prints-container p {
	color: #fff;
	color: var(--white);
}



.about-group, .activities-group, .prints-group {
	grid-column: 2 / 3;
	grid-row: 2 / 3;
	/* max-width: 400px; */
	}

	.img-ratio {
		grid-column: 1 / 5;
		grid-row: 4 / 5;
	}

	.img-ratio img {
		max-width: 100%;
	}

	.img-ratio-flipped {
		grid-column: 1 / 5;
		grid-row: 4 / 5;
	}
	
	.img-ratio-flipped img {
		max-width: 100%;
	}

	.about-group, .activities-group, .prints-group {
		padding: 2rem 2rem 3.5rem 2.5rem;
	}

/*  ACTIVITIES */
@media (min-width:769px) {
	.about-container, .activities-container, .prints-container {
		grid-template-columns: unset;
		grid-template-rows: unset;
		grid-column-gap: unset;
	}
}

@media only screen and (min-width:769px) {
	.about-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 2rem;
	
	}
	
	.about-group {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		/* background-color: pink; */
	}

	.img-ratio {
		grid-column: 2 / 3;
		grid-row: 1 / 2;
	}
	
	.img-ratio img {
		height: 100%;
		object-fit: cover; 
		overflow: hidden;
	}
	

	.activities-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
	}
	
	.img-ratio-flipped {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}
	
	.img-ratio-flipped img {
		height: 100%;
		object-fit: cover;
		overflow: hidden;
	}
	
	.activities-group {
		grid-column: 2 / 3;
		grid-row: 1 / 2;
	}
	
	/*  PRINTS */
	.prints-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	
	.prints-group {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}
	
	.about-group, .activities-group, .prints-group {
		padding: 5rem 5rem 6rem 6rem;
	}
}

/* ---------------------------------- */
   
/*  LARGE QUOTE CENTERED */

/* ---------------------------------- */

.quote-container {
	background-color: #de6d5a;
	background-color: var(--deep-rose);
	display: grid;
	grid-template-columns: 1.5rem minmax(250px, 1fr) 1.5rem;
	grid-template-rows: .5fr minmax(100px, 1fr) .5fr;
}

.text-container {
	grid-column: 2 / 3;
	grid-row: 2 / 3;
}

.quote {
	font-family: 'Open Sans', sans-serif;
	font-family: var(--pFont);
	font-size: 2rem;
	line-height: 2.5rem;
	letter-spacing: 4px;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	color: var(--white);
	margin: 0;
}

/*  LARGE QUOTE CENTERED - min-768px */
@media only screen and (min-width:769px) {
	.quote-container {
		grid-template-columns: unset;
		grid-template-rows: unset;
	}
}
	
@media only screen and (min-width:769px) {
	.quote-container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 100px 300px 100px;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
  }
	
	.text-container {
		grid-column: 2 / 4;
		grid-row: 2 / 3;
  }

	.quote {
		font-size: 4rem;
		font-weight: 300;
		line-height: 5rem;
		letter-spacing: .2rem;
		text-transform: uppercase;
		color: #fff;
		color: var(--white);
	}
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

