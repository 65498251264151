.activity-list-container {
  background-color: var(--deep-rose);
  padding: 2.5rem 2rem;
}

.act-list-top-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.add-btn {
  display: inline-block;
	margin-top: 1rem;
	margin-right: 1rem;
	padding: .9rem 1rem;
	text-decoration: none;
	font-size: 1.5rem;
	letter-spacing: 1px;
	font-weight: 600;
  border: 2px solid var(--white);
	color: var(--white);
}

.activities-list {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activity-item {
  
  margin-bottom: 1rem;
  padding: .5rem;
}

.activity-list-container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width:769px) {
  .activities-list {
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 2.rem;
  }
}