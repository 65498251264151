.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 1rem;
  background: var(--white);
  z-index: 1;
}
.nav-header {
  display: flex;
  justify-content: space-between;
}

.nav-header h1 {
  margin: 0;
  font-size: 2rem;
}

.logo-link {
  color: var(--primary);
  text-decoration: none;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
}

.nav-icon {
  position: relative;
  top: 8px;
  left: 6px;
  font-size: 1.5rem;
  color: var(--primary);
}

.nav-links {
  display: flex;
  height: 0;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
}

.nav-links a {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: var(--primary);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}

.links-container ul {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.nav-links li {
  padding-left: 1rem;
  padding-top: .5rem;
}

.nav-links a:hover {
  color: var(--medBlue);
}

.show-nav {
  height: 25px;
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }

  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .links-container {
    display: flex;
    flex-direction: row;
    width: 760px;
    margin-left: 2rem;
  }

  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
  }

  .nav-links a {
    justify-content: center;
    padding: 0.5rem 0;
  }

  .nav-path-link {
    text-align: center;
    margin: 0;
  }
}